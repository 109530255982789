import consumer from "./consumer"

document.addEventListener('DOMContentLoaded', (event) => {

  const productElement = document.querySelector('[data-controller="product"]');

  if (productElement) {
    let productId = productElement.getAttribute("data-product-id-value");

    consumer.subscriptions.create({ channel: "ProductPhotosChannel", product_id: productId }, {
      connected() {
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        if (data.status === 'ready') {
          // Get the value of data-product-color-code-value="<%= @color_code %>">
          let productColorCode = document.querySelector('[data-product-color-code-value]').getAttribute("data-product-color-code-value");
          let productGallery = document.querySelector("#product-gallery");
          let largePhotoDiv = productGallery.querySelector("#product-gallery-large-photo");
          let thumbnailListDiv = productGallery.querySelector("#product-gallery-thumbnail-list");

          largePhotoDiv.innerHTML = ''; // clear the div first
          thumbnailListDiv.innerHTML = ''; // clear the div first

          let largeImg;

          // For each photo, create a new img element and append it to the largePhotoDiv
          // and create a thumbnail and append it to thumbnailListDiv
          data.photos.forEach((photo, index) => {
            if (index === 0) {
              largeImg = document.createElement("img");
              largeImg.src = photo.url;
              largeImg.alt = photo.label;
              largeImg.style.backgroundColor = productColorCode;
              largeImg.classList.add("product-photo-large");
              largeImg.classList.add("product-gallery-preview");
              largePhotoDiv.appendChild(largeImg);

            }

            let thumbnailImg = document.createElement("img");
            // Add a data-index attribute to the thumbnailImg
            thumbnailImg.setAttribute("data-index", index);
            thumbnailImg.src = photo.url;
            thumbnailImg.alt = photo.label;
            thumbnailImg.classList.add("product-gallery-thumblist-item");
            thumbnailImg.style.backgroundColor = productColorCode;
            thumbnailListDiv.appendChild(thumbnailImg);
          });
          let productGalleryThumblistItems = thumbnailListDiv.querySelectorAll('.product-gallery-thumblist-item');
          productGalleryThumblistItems.forEach(function(item) {
            item.addEventListener('click', function(e) {
              e.preventDefault();
              // remove active class from all product-gallery-thumblist-item
              productGalleryThumblistItems.forEach(function(item) {
                item.classList.remove('active');
              });

              // add active class to clicked product-gallery-thumblist-item not the image
              item.classList.add('active');

              largeImg.src = e.target.src;

            });
          });

            // Hide the loading screen
          document.getElementById('loading-screen').style.display = 'none';
          window.konvaStage.destroy();
          window.konvaStage = null;

        }
      }

    });
  }
});


